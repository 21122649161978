
import { computed, defineComponent, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Cart",

  setup() {
    const { proxy }: any = getCurrentInstance();
    const store = useStore();

    // 购物车数量
    const cartArr = computed(() => {
      return store.state.cartArr;
    });

    // 添加购物车
    function addCart(gid: any) {
      store.commit("addCart", gid);
    }

    // 减少购物车
    function subCart(gid: any) {
      store.commit("subCart", gid);
    }

    // 清空购物车
    function clearCart() {
      store.commit("clearCart");
    }

    // 提交订单
    function submit() {
      proxy.$toast.success("提交成功");
    }

    return { cartArr, addCart, subCart, clearCart, submit };
  },
});
